import { DOCUMENT/*, isPlatformBrowser*/ } from '@angular/common';
import { Component, Inject, Input, OnInit/*, PLATFORM_ID */} from '@angular/core';
// import { TransferState } from '@angular/platform-browser';
// import { Router } from '@angular/router';
// import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
// import { faChevronDown, faChevronRight, faEllipsisH, faSearch, faTimes, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
// import { compareAsc, parseJSON } from 'date-fns';
import { Observable, forkJoin, map, shareReplay } from 'rxjs';
import { AuthHelperService } from 'src/app/auth-helper.service';
// import { BlogArticle } from 'src/app/blog-article.model';
import { BlogService } from 'src/app/blog.service';
// import { Match } from 'src/app/match.model';
import { MatchService } from 'src/app/match.service';
import { NavigationItem } from 'src/app/navigation-item.model';
// import { SportDataService } from 'src/app/sport-data.service';
import { Sport } from 'src/app/sport.model';

@Component({
	selector: 'app-desktop-navigation',
	templateUrl: './desktop-navigation.component.html',
	styleUrls: ['./desktop-navigation.component.scss']
})
export class DesktopNavigationComponent implements OnInit {
	
	// searchBarActive = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	// searchText = "";
	// isBrowser: boolean;
	sportsbooks = [];
    dfsProviders = [];
    promoCodes = [];

	// faChevronRight = faChevronRight;
	// faChevronDown = faChevronDown;
	// faEllipsisH = faEllipsisH;
	// faSearch = faSearch;
	// faTimes = faTimes;
	// faExternalLinkAlt = faExternalLink;
	// faCircleUser = faCircleUser;
    states =
        [
		{ name: 'North Carolina', iconLink: 'https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg'},
		{ name: 'Vermont', iconLink: 'https://cdn.cosmicjs.com/c9813100-115a-11ee-a61c-b352d78f91a5-Vermont.svg'},
        { name: 'Kentucky', iconLink: 'https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=25' }, { name: 'Arizona', iconLink: 'https://imgix.cosmicjs.com/ec3f30c0-1001-11ee-b1c4-fdbd98303cc6-Arizona.svg?auto=format,compress&w=25' },
        { name: 'Colorado', iconLink: 'https://imgix.cosmicjs.com/ec401b20-1001-11ee-b1c4-fdbd98303cc6-Colorado.svg?auto=format,compress&w=25' },{ name: 'Illinois', iconLink: 'https://imgix.cosmicjs.com/ec409050-1001-11ee-b1c4-fdbd98303cc6-Illinois.svg?auto=format,compress&w=25' }, 
        { name: 'Indiana', iconLink: 'https://imgix.cosmicjs.com/ec428c20-1001-11ee-b1c4-fdbd98303cc6-Indiana.svg?auto=format,compress&w=25' },{ name: 'Kansas', iconLink: 'https://imgix.cosmicjs.com/ec437680-1001-11ee-b1c4-fdbd98303cc6-Kansas.svg?auto=format,compress&w=25' }, 
        { name: 'Louisiana', iconLink: 'https://imgix.cosmicjs.com/ec460e90-1001-11ee-b1c4-fdbd98303cc6-Louisiana.svg?auto=format,compress&w=25' },{ name: 'Maryland', iconLink: 'https://imgix.cosmicjs.com/ec46d1e0-1001-11ee-b1c4-fdbd98303cc6-Maryland.svg?auto=format,compress&w=25' }, 
        { name: 'Massachusetts', iconLink: 'https://imgix.cosmicjs.com/ec5d1900-1001-11ee-b1c4-fdbd98303cc6-Massachusetts.svg?auto=format,compress&w=25' },{ name: 'Michigan', iconLink: 'https://imgix.cosmicjs.com/ec5f3be0-1001-11ee-b1c4-fdbd98303cc6-Michigan.svg?auto=format,compress&w=25' }, 
        { name: 'New Jersey', iconLink: 'https://imgix.cosmicjs.com/700a2660-b036-11ee-baae-2b7553ec3713-New-Jersey.svg?auto=format,compress&w=25' },{ name: 'New York', iconLink: 'https://imgix.cosmicjs.com/ec5fb110-1001-11ee-b1c4-fdbd98303cc6-New-York.svg?auto=format,compress&w=25' }, 
        { name: 'Ohio', iconLink: 'https://imgix.cosmicjs.com/ec602640-1001-11ee-b1c4-fdbd98303cc6-Ohio.svg?auto=format,compress&w=25' },{ name: 'Pennsylvania', iconLink: 'https://imgix.cosmicjs.com/ec5e9fa0-1001-11ee-b1c4-fdbd98303cc6-Pennsylvania.svg?auto=format,compress&w=25' }];


    affiliateAccountDropdownData: NavigationItem = {
		type: 'user-dropdown',
		subLinks: [
			{
				type: 'link',
				title: 'My Dimers',
				subtitle: 'Your dashboard',
				href: '/my-account',
				onClick: () => this.selectTab('dashboard')
			},
			{
				type: 'link',
				title: 'Details',
				subtitle: 'Manage your account',
				href: '/my-account',
				onClick: () => this.selectTab('details')
				
			},
			{
				type: 'link',
				title: 'Subscription',
				subtitle: 'Manage your plan',
				href: '/my-account',
				onClick: () => this.selectTab('subscription')
				
			},
			{
				type: 'link',
				title: 'Preferences',
				subtitle: 'Customize your experience',
				href: '/my-account',
				onClick: () => this.selectTab('preferences')
				
			},
            {
				type: 'link',
				title: 'Affiliate Dashboard',
				subtitle: 'Your affiliate dashboard',
				href: 'https://dimers.promotekit.com/',
                strictHref: true,
			},
			{
				type: 'link',
				title: 'Log out',
				onClick: () => this.authHelper.logout({ returnTo: this.document.location.origin })
			},
		]
	}
    accountDropdownData: NavigationItem = {
		type: 'user-dropdown',
		subLinks: [
			{
				type: 'link',
				title: 'My Dimers',
				subtitle: 'Your dashboard',
				href: '/my-account',
				onClick: () => this.selectTab('dashboard')
			},
			{
				type: 'link',
				title: 'Details',
				subtitle: 'Manage your account',
				href: '/my-account',
				onClick: () => this.selectTab('details')
				
			},
			{
				type: 'link',
				title: 'Subscription',
				subtitle: 'Manage your plan',
				href: '/my-account',
				onClick: () => this.selectTab('subscription')
				
			},
			{
				type: 'link',
				title: 'Preferences',
				subtitle: 'Customize your experience',
				href: '/my-account',
				onClick: () => this.selectTab('preferences')
				
			},
			{
				type: 'link',
				title: 'Log out',
				onClick: () => this.authHelper.logout({ returnTo: this.document.location.origin })
			},
		]
	}

	sportsDropdownData: NavigationItem;
	bettingDropdownData: NavigationItem;

	propsPageOptions: Array<NavigationItem> = [];
	picksPageOptions: Array<NavigationItem> = [];
	parlayPickerPageOptions: Array<NavigationItem> = [];

   

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

    affiliate$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_affiliate
		}),
		shareReplay(1)
	);
    
	constructor(
		// private router: Router,
		public authHelper: AuthHelperService,
		private blogService: BlogService,
		// private sportDataService: SportDataService,
		public matchService: MatchService,
		// @Inject(PLATFORM_ID) platformId: Object,
		@Inject(DOCUMENT) public document: Document,
	) {
		// this.isBrowser = isPlatformBrowser(platformId);
	}
	
	ngOnInit(): void {

		forkJoin([
			this.blogService.sportsBookReviewLinks$,
			this.blogService.dfsReviewLinks$,
			this.blogService.getGeneralPromoCodeLinks()
		]).subscribe(([sportsBookReviewLinks, dfsReviewLinks, generalPromoCodeLinks]) => {
			this.sportsbooks = sportsBookReviewLinks;
			this.dfsProviders = dfsReviewLinks;
			this.promoCodes = generalPromoCodeLinks;

			this.bettingDropdownData = {
				type: "dropdown",
				href: "/best-books",
				title: "Betting & DFS",
				subLinks: [
					{
						type: "dropdown",
						title: "US Legal Betting",
						href: "/best-books",
						subLinks: [
							...this.states.map(state => ({
								type: "link" as "link",
								title: state.name,
								iconPath: state.iconLink,
								href: `/best-books/${state.name.toLowerCase().replace(' ', '-')}`
							})),
							{
								type: "link",
								title: "All Other States",
								iconPath: "https://cdn.cosmicjs.com/872b64b0-10bf-11ee-925e-ebfb5caceee1-United-states.svg",
								href: "/best-books",
							}
						]
					},
					{
						type: "dropdown",
						title: "Canada Betting",
						subLinks: [
							{
								type: "link",
								title: "Ontario",
								iconPath: "https://imgix.cosmicjs.com/1945bfe0-0c3b-11ef-911e-9f1d49670742-Ontario-white.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/ontario",
							},
							{
								type: "link",
								title: "DraftKings Ontario",
								iconPath: "https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/draftkings-canada",
							},
							{
								type: "link",
								title: "BetMGM Ontario",
								iconPath: "https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/betmgm-canada",
							},
							{
								type: "link",
								title: "Betano Ontario",
								iconPath: "https://imgix.cosmicjs.com/dd4755a0-be68-11ee-8229-0f62eab2dd82-Betano-icon.png?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/betano",
							},
							{
								type: "link",
								title: "Betway Ontario",
								iconPath: "https://imgix.cosmicjs.com/6b14fe80-0c36-11ef-911e-9f1d49670742-Betway-small-1.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/betway",
							},
							// {
							// 	type: "link",
							// 	title: "Pinnacle Ontario",
							// 	iconPath: "https://imgix.cosmicjs.com/644f4a60-0c36-11ef-911e-9f1d49670742-Pinacle-small-1.svg?q=&auto=compress,enhance,format&w=20",
							// 	href: "/ca/betting/reviews/pinnacle",
							// },
							{
								type: "link",
								title: "Bet365 Ontario",
								iconPath: "https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/bet365-canada",
							},
                            {
								type: "link",
								title: "PointsBet Ontario",
								iconPath: "https://imgix.cosmicjs.com/98ab8830-4861-11ee-89ab-17371fc03105-PointsBet-icon.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/pointsbet-canada",
							},
                            {
								type: "link",
								title: "BetVictor Ontario",
								iconPath: "https://imgix.cosmicjs.com/ca8eaaf0-455c-11ef-8f56-adb81d8aea77-BetVictor-Small-White-2.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/betvictor-canada",
							},
                            {
								type: "link",
								title: "Sports Interaction Ontario",
								iconPath: "https://imgix.cosmicjs.com/20e2df50-4dc5-11ef-926f-211de7a312da-Copy-of-SILogoStackedSmallColour.png?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/sports-interaction",
							},
                            {
								type: "link",
								title: "PowerPlay Ontario",
								iconPath: "https://imgix.cosmicjs.com/451aba80-6339-11ef-b164-a75e20f7a9e0-PowerPlay-logo.svg?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/powerplay-canada",
							}
                            ,
                            {
								type: "link",
								title: "888 Ontario",
								iconPath: "https://imgix.cosmicjs.com/8e281420-5e84-11ef-86e1-b75943817551-888casino-small.png?q=&auto=compress,enhance,format&w=20",
								href: "/ca/betting/reviews/888-canada",
							}
						]
					},
					{
						type: "dropdown",
						title: "Sportsbook Reviews",
						subLinks: this.sportsbooks.map(sportsbook => ({
							type: "link",
							title: sportsbook.metadata.sportsbook.title,
							iconPath: sportsbook.metadata.sportsbook.metadata.promos_small_logo.imgix_url + "?auto=format,compress&w=20",
							href: `/best-books/reviews/${sportsbook.metadata.sportsbook.slug}`
						}))
					},
					{
						type: "dropdown",
						title: "DFS Reviews",
						subLinks: this.dfsProviders.map(item => ({
							type: "link",
							title: item.metadata.sportsbook.title,
							iconPath: item.metadata.sportsbook.metadata.promos_small_logo.imgix_url + "?auto=format,compress&w=20",
							href: `/dfs/reviews/${item.metadata.sportsbook.slug}`
						}))
					},
					{
						type: "dropdown",
						title: "Sportsbook Promos",
						subLinks: this.promoCodes.map(item => ({
							type: "link",
							title: `${item.metadata.sportsbook.title} Promo`,
							iconPath: item.metadata.sportsbook.metadata.promos_small_logo.imgix_url + "?auto=format,compress&w=20",
							href: `/best-books/bonuses/${item.metadata.sportsbook.slug}`
						}))
					},
					{
						type: "link",
						title: "North Carolina Betting",
						subLinks: [
							{
								type: "link",
								title: "North Carolina Promos",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/bonuses"
							},
							{
								type: "link",
								title: "ESPN Bet NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/espn-bet-promo-review"
							},
							{
								type: "link",
								title: "Fanatics NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/fanatics-promo-review"
							},
							{
								type: "link",
								title: "Underdog NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/underdog-promo-review"
							},
							{
								type: "link",
								title: "BetMGM NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/betmgm-promo-review"
							},
							{
								type: "link",
								title: "DraftKings NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/draftkings-promo-review"
							},
							{
								type: "link",
								title: "FanDuel NC Promo",
								iconPath: "https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20",
								href: "/best-books/north-carolina/fanduel-promo-review"
							},
						]
					},
					{
						type: "dropdown",
						title: "Vermont Betting",
						subLinks: [
							{
								type: "link",
								title: "Vermont Promos",
								iconPath: "https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20",
								href: "/best-books/vermont/bonuses",
							},
							{
								type: "link",
								title: "DraftKings VT Promo",
								iconPath: "https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20",
								href: "/best-books/vermont/draftkings-promo-review",
							},
							{
								type: "link",
								title: "FanDuel VT Promo",
								iconPath: "https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20",
								href: "/best-books/vermont/fanduel-promo-review",
							},
						]
					},
					{
						type: "dropdown",
						title: "Kentucky Betting",
						subLinks: [
							{
								type: "link",
								title: "Kentucky Promos",
								iconPath: "https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20",
								href: "/best-books/kentucky/bonuses"
							},
							{
								type: "link",
								title: "DraftKings KY Promo",
								iconPath: "https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20",
								href: "/best-books/kentucky/draftkings-promo-review"
							},
							{
								type: "link",
								title: "bet365 KY Promo",
								iconPath: "https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20",
								href: "/best-books/kentucky/bet365-promo-review"
							},
							{
								type: "link",
								title: "FanDuel KY Promo",
								iconPath: "https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20",
								href: "/best-books/kentucky/fanduel-promo-review"
							},
							{
								type: "link",
								title: "BetMGM KY Promo",
								iconPath: "https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20",
								href: "/best-books/kentucky/betmgm-promo-review"
							},
						]
					},
					{
						type: "dropdown",
						title: "NFL Betting",
						subLinks: [
							{
								type: "link",
								title: "Best NFL Promos",
								href: "/best-books/bonuses/nfl"
							},
							{
								type: "link",
								title: "Best NFL Betting Sites",
								href: "/best-books/reviews/nfl-betting-sites"
							}
						]
					},
				]
			};
		})

		this.sportsDropdownData = {
			type: "dropdown",
			title: "Sports",
			href: "/sports",
			subLinks: [
				...this.dropdownSports.map(sport => ({
					type: "dropdown" as "dropdown",
					title: sport.shortName,
					href: sport.navigablePages[0]?.path || undefined,
					queryParams: sport.navigablePages[0]?.queryParams || undefined,
					subLinks: sport.navigablePages.map(page => ({
						type: "link" as "link",
						href: page.path,
						queryParams: page.queryParams,
						title: page.name,
					}))
				})),
				{
					type: "dropdown",
					title: "Other Sports",
					subLinks: this.otherSports.map(sport => ({
						type: "link",
						href: sport.navigablePages[0].path,
						queryParams: sport.navigablePages[0].queryParams,
						title: sport.shortName,
					}))
				}
			]
		}

		this.propsPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/prop-bets"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "best-props")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("best-props")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("best-props")).queryParams,
			}))
		]

		this.picksPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/best-bets"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "best-bets")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("best-bets")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("best-bets")).queryParams,
			}))
		]

		this.parlayPickerPageOptions = [
			{
				type: "link",
				title: "All Sports",
				href: "/parlay-picker"
			},
			...this.dropdownSports.filter(s => s.navigablePages.some(p => p.pageCode === "parlay-picker")).map(sport => ({
				type: "link" as "link",
				title: sport.shortName,
				href: sport.navigablePages.find(p => p.pageCode === ("parlay-picker")).path,
				queryParams: sport.navigablePages.find(p => p.pageCode === ("parlay-picker")).queryParams,
			}))
		]
	}

	
	// closeSearch(): void {
	// 	this.searchBarActive = false;
	// 	this.searchText = "";
	// }
	
	// advanceSearch(): void {
	// 	if (!this.searchBarActive) {
	// 		this.searchBarActive = true;
	// 	} else if (this.searchText) {
	// 		this.submitSearch();
	// 	}
	// }
	
	// submitSearch(): void {
	// 	this.router.navigateByUrl("/search?q=" + this.searchText)
	// }

    selectTab(tab: string) {
        this.blogService.setSelectedMyaccountTab(tab);
    }

    openProModal() {
        // Open Pro modal
        this.blogService.setProModalData(true);
    }
}