<app-sub-footer></app-sub-footer>

<app-about-dimers [class.hidden-partner]="!this.showPartner"></app-about-dimers>
<app-visit-our-partners></app-visit-our-partners>
<div class="signup-footer">
	<div class="signup-footer-message">
		<h2 class="f-38 f-bold main-content-header">
			Get the Latest From Dimers
		</h2>
		<div class="f-16 main-content-header">
			Free picks, parlays and sportsbook offers sent to your inbox.
		</div>
	</div>
	<!-- <div
		#formContainer
		class="signup-footer-desktop"
	></div> -->
	<!-- <div
		#formContainerMobile
		class="signup-footer-mobile"
	></div> -->
	<div class="form-shell">
		<div
			class="form"
			*ngIf="!formSent"
			ref="form"
		>
			<input
				type="email"
				[(ngModel)]="email"
				placeholder="Email"
				name="email"
				ngDefaultControl
				(keyup)="verifyForm()"
			>

			<div
				color="primary"
				class="submit-button"
				[class.button-enabled-yellow]="formValid && !formSending"
				[class.button-disabled-yellow]="!formValid || formSending"
				(click)="sendEmail()"
			>
				{{ formSending ? 'Please wait...' : 'Let\'s Go' }}
			</div>
		</div>
		<div class="send-info"
			*ngIf="formSent && !formError"
		>
			Thanks for joining. Stay tuned for more!
		</div>
		<div
			*ngIf="formError"
			class="send-info"
		>
			Sorry, something went wrong sending your details. Please try again.
		</div>

		<div class="form-disclaimer">
			You can unsubscribe at any time. By signing up you agree to our
			<a href="/terms-of-service" target="_blank">Terms of Service</a> and
			<a href="/privacy-policy" target="_blank">Privacy Policy</a>.
		</div>
	</div>

</div>
<div class="white-line"></div>
<div class="footer-content">
	<div class="footer-logo">
		<div class="cipher-logo">
			<div  class="logo-header">
				Proudly Part of
			</div>
			<div class="logo">
				<a class="img-ctr" href="https://ciphersports.io/" target="_blank" rel="noreferrer" > <img loading="lazy" alt="Cipher Sports Group" src="https://cdn.ciphersports.io/assets/cipher-logo.svg" width="103" height="22"/></a>
			</div>
		</div>
		<div class="dimers-logo">
			<a routerLink="/">
				<img alt="Dimers" loading="lazy" src="https://cdn.ciphersports.io/images/dimers-site/dimers-yellow.svg" alt="Dimers"  width="165" height="40">
			</a>
		</div>
		<div class="sportradar-logo">
			<div class="logo-header">
				Some data provided by		
			</div>
			<div class="logo">
				<img loading="lazy" alt="SportRadar" src="https://cdn.ciphersports.io/assets/sportsradar-logo_60h.png" width="128" height="32"/>
			</div>
		</div>
		<div class="social-links">
			<div class="social-links-header">
				Follow Us
			</div>
			<div class="social-link">
				<a href="https://twitter.com/DimersCom" target="_blank" rel="noreferrer" >
					<fa-icon
						[icon]="faTwitter"
						role="link"
						aria-label="Twitter"
					></fa-icon>
				</a>
				<a href="https://www.facebook.com/DimersCom/" target="_blank" rel="noreferrer" >
					<fa-icon
						[icon]="faFacebook"
						role="link"
						aria-label="Facebook"
					></fa-icon>
				</a>
				<a href="https://www.instagram.com/DimersCom/" target="_blank" rel="noreferrer" >
					<fa-icon
						[icon]="faInstagram"
						role="link"
						aria-label="Instagram"
					></fa-icon>
				</a>
				<!-- <a href="https://dime.rs/discord" target="_blank" rel="noreferrer">
					<fa-icon
						[icon]="faDiscord"
						role="link"
						aria-label="Discord"
					></fa-icon>
				</a> -->
			</div>
		</div>
	</div>
	<div class="footer-links">
		<a routerLink="/about-us">About Us</a>
		<a routerLink="/contact-us">Contact Us</a>
		<a routerLink="/news/authors">Writers</a>
		<a routerLink="/privacy-policy">Privacy Policy</a>
		<a routerLink="/terms-of-service">Terms of Service</a>
		<a routerLink="/sitemap">Sitemap</a>
		<a routerLink="/live-now">Live Scores</a>
		<!-- <a routerLink="/bet-center">Track Your Bets</a> -->
		<a routerLink="/sports-betting-101">Sports Betting 101</a>
		<a routerLink="/best-books">Sportsbook Reviews</a> 
		<a routerLink="/responsible-gambling">Gambling Responsibly</a> 
        <a routerLink="/editorial-guidelines">Editorial Guidelines</a> 
        <a routerLink="/our-data">Our Data</a> 
        <a routerLink="/business">Dimers For Business</a> 
	</div>
	<div class="state-disclaimer" *ngIf="state === 'massachusetts'">
		<p>
			Must be 21+. Gambling problem? Call <a href="tel:+18003275050">1&#8209;800&#8209;327&#8209;5050</a> or visit <a href="https://helplinema.org" target="_blank">https://helplinema.org</a>.
		</p>
		
	</div>
	<div class="state-disclaimer" *ngIf="state === 'ohio'">
		<p>
			Must be in Ohio. 21+. Gambling Problem? Call <a href="tel:+18003272537">1&#8209;800&#8209;GAMBLER</a>.
		</p>
		
	</div>
	<div class="disclaimer">
		<p class="text-xs text-center">
			Disclaimer: All of the information on this site is for entertainment purposes only. We do NOT accept bets of any kind. The information we provide is accurate and trustworthy to help you make better decisions. When you click or tap on a link on Dimers that leads to a third-party website that we have a commercial arrangement with (such as an online sportsbook), we may earn referral fees. Dimers does not endorse or encourage illegal or irresponsible gambling in any form. Before placing any wagers with any betting site, you must check the online gambling regulations in your jurisdiction or state, as they do vary. If you or someone you know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER.
		</p>
		<p class="text-xs text-center mt-2">
			Copyright © 2024 Dimers. All Rights Reserved. Proudly part of Cipher Sports Technology Group, 902A Broadway, Floors 6 & 7, New York, NY 10010, United States of America.
		</p>
	</div>
</div>