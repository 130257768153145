<div class="modal-overlay fixed w-full h-full top-0 left-0 z-[999] no-doc-scroll" *ngIf="visible"> 
    <div class="backdrop-blur-sm bg-black/50 w-full h-full" (click)="setVisible()">
	</div>
    <div class="modal-dialog flex max-h-[90%] w-full md:w-[720px] lg:w-[800px] bottom-0 md:bottom-auto md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 absolute">
        <div class="rounded-t-3xl md:rounded-3xl overflow-auto max-md:w-full grid grid-cols-1 md:grid-cols-2"  *ngIf="!basicUser">
            <div class="flex md:hidden close-button leading-none top-2 right-2 cursor-pointer absolute h-[28px] min-w-[28px] bg-black/60 rounded-full items-center justify-center" (click)="setVisible()">
                <fa-icon class="text-white text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
            </div>
            <div class="primary-panel bg-white text-black p-8 md:p-6 lg:p-8 flex-1 flex flex-col justify-center items-stretch gap-5 md:gap-4 lg:gap-6 max-w-full aspect-[1240 / 211]">
                <!-- Logo (desktop) -->
                <!-- <div *ngIf="selectedFrequency === 'yearly'" class="text-sm font-medium text-white px-3 py-1 rounded bg-highlight-100 max-w-max">EARLY BIRD</div> -->
                <img class="hidden md:inline w-full h-auto" alt="Dimers Pro" src="https://cdn.ciphersports.io/assets/dimers-pro-full-logo.svg" height="211" width="1240">
                <!-- TODO Logo-text box (mobile) -->
                <div
                    class="icon-text-box bg-gradient-to-b from-AIDimers-grey border border-AIDimers-grey rounded-3xl flex md:hidden flex-col justify-center items-center p-4 gap-2">
                    <ng-container *ngIf="!betIntent">
                        <img class="w-4/5 h-auto max-h-10" alt="Dimers Pro"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-full-logo.svg" height="211" width="1240">
                        <div class="text-sm text-center font-medium text-highlight-100">Every edge, every game, every day</div>
                    </ng-container>
                
                    <ng-container *ngIf="betIntent">
                        <img class="" alt="Dimers Pro"
                            src="https://imgix.cosmicjs.com/9a4e0b30-d1f6-11ee-babb-b99b48a02ac8-dimers-pro-logo.svg" height="40"
                            width="40">
                        <div class="text-sm text-gray-900 text-center font-medium">
                            Dimers Pro users get access to an average of
                            200 best bets per day.
                        </div>
                    </ng-container>
                </div>
                <!-- Frequency toggle -->
                <div class="frequency-options flex flex-row justify-center items-stretch gap-2">
                    <div class="frequency-option flex-1 py-1 px-3 flex flex-col justify-center items-center rounded-sm text-sm leading-6"
                        [class]="selectedFrequency === option ? 'bg-highlight-100 text-white' : 'cursor-pointer bg-white text-black'"
                        *ngFor="let option of frequencyOptions"
                        (click)="setFrequency(option)"
                    >
                        {{ option | titlecase }}
                    </div>
                </div>
                <!-- Price display -->
                <div class="price-display flex flex-col justify-center items-stretch gap-1 text-AIDimers-black">
                    <div class="price-line flex flex-row justify-center items-baseline gap-1">
                        <span class="price-main text-3xl font-bold text-center">
                            <ng-container *ngIf="selectedFrequency === 'weekly'">$9.99</ng-container>
                            <ng-container *ngIf="selectedFrequency === 'monthly'"><span class="text-highlight-100"> $24.99</span></ng-container>
                            <ng-container *ngIf="selectedFrequency === 'yearly'">$249.99</ng-container>
                        </span>
                        <span class="price-note text-sm font-semibold">
                            <ng-container *ngIf="selectedFrequency === 'weekly'">/ week</ng-container>
                            <ng-container *ngIf="selectedFrequency === 'monthly'">/ month</ng-container>
                            <ng-container *ngIf="selectedFrequency === 'yearly'">/ year</ng-container>
                        </span>
                    </div>
                    <div class="price-note text-sm font-semibold text-center">
                        <ng-container *ngIf="selectedFrequency === 'weekly'"><span class="bg-blue-200 text-highlight-100 rounded block py-2">Flexibility at its best</span> </ng-container>
                        <ng-container *ngIf="selectedFrequency === 'monthly'"><span class="bg-blue-200 text-highlight-100 rounded block py-2">Save 40% compared to weekly</span></ng-container>
                        <ng-container *ngIf="selectedFrequency === 'yearly'">
                            <!-- <div class="flex items-center justify-center border-dashed border rounded-md py-2 border-black font-semibold text-gray-900 relative">CODE: PICK6
                                <button  (click)="copyCode()" cdkCopyToClipboard="PICK6" class="ml-3">
                                    <span id="default-icon flex flex-col" *ngIf="!startCopy">
                                        <fa-icon aria-hidden="true" [icon]="faCopy"></fa-icon>
                                    </span>
                                    <span id="success-icon" *ngIf="startCopy" class="inline-flex items-center text-green-600">
                                        <fa-icon aria-hidden="true" [icon]="faCheck"></fa-icon>
                                    </span>
                                </button>
                            </div> -->
                            <span class="bg-blue-200 text-highlight-100 rounded block py-2">Save 50% when you sign up for annual</span>
                        </ng-container>
                    </div>
                </div>
                <!-- Heading - Medium+ only -->
                <div class="heading text-xl font-semibold text-start hidden md:block">
                    Never miss another betting opportunity
                </div>
                <!-- Heading - Small only -->
                <!-- <div class="heading text-xl font-semibold text-start md:hidden text-highlight-100">
                    Every bet, every game, every day
                </div> -->
                <!-- Body copy -->
                <div class="sub-body text-AIDimers-blacklight -mt-3">
                    <div class="hidden md:block">
                        <!-- <ng-container *ngIf="selectedFrequency === 'yearly'">
                            <div class="mt-2 text-base text-gray-900 font-medium leading-6">
                                Pick our special <span class="text-highlight-100">six-month pricing</span>  when you create an annual account for football season. Get the rest of the year free.
                            </div>
                        </ng-container> -->
                        <!-- <ng-container *ngIf="selectedFrequency !== 'yearly'"> -->
                            Access the smartest sports betting models in the market and join millions of Dimers
                            users who trust our data-driven products to help them place better bets.
                        <!-- </ng-container> -->
                    </div>
                    
    
                    <div class="block md:hidden mt-2">
                        <ul class="divide-y list-none m-0 border-t border-b">
                            <li class="flex gap-2 py-1 text-base font-medium"><img
                                 alt="icon" width="24" height="24"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                Unlimited best bets </li>
                            <li class="flex gap-2 py-1 text-base font-medium"><img
                                 alt="icon" width="24" height="24"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                Unlimited props </li>
                            <li class="flex gap-2 py-1 text-base font-medium"><img
                                 alt="icon" width="24" height="24"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                Unlimited trends </li>
                            <li class="flex gap-2 py-1 text-base font-medium"><img
                                 alt="icon" width="24" height="24"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                Unlimited customizable parlays </li>
                            <li class="flex gap-2 py-1 text-base font-medium"><img
                                 alt="icon" width="24" height="24"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                    And more features. <a href="/subscription" routerLink="/subscription" class="underline" (click)="setVisible()">Learn more</a></li> 
                        </ul>
                    </div>
                </div>
                <!-- Subscribe button (not logged in), plus login option underneath -->
                <div class="subscribe-button" *ngIf="authHelper.userRetrievalDone && !authHelper.user" (click)="authHelper.goToLoginWithState(true, '/pro-purchase/' + selectedFrequency)">
                    Subscribe
                </div>
                <div class="login-text text-AIDimers-blackLight cursor-pointer text-center" *ngIf="authHelper.userRetrievalDone && !authHelper.user"
                    (click)="authHelper.goToLoginWithState(false, '/pro-purchase/' + selectedFrequency)">
                    Already have an account? <span class="underline text-highlight-100">Log in</span>
                </div>
                
                <!-- Subscribe button (logged in) -->
                <a class="subscribe-button" routerLink="/pro-purchase/{{ selectedFrequency }}" *ngIf="authHelper.userRetrievalDone && authHelper.user && !processing" (click)="redirecting()">
                    Subscribe
                </a>
                <button type="button" *ngIf="processing" class="flex justify-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-highlight-100/60 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Please wait...
                </button>
    
            </div>
            <div class="secondary-panel bg-AIDimers-grey text-black p-8 md:p-6 lg:p-8 flex-1 hidden md:flex flex-col justify-center items-stretch gap-3 md:gap-2 lg:gap-3">
                <!-- Icon and Title -->
                <div class="flex flex-row justify-start items-center gap-2">
                    <img class="h-8 w-8" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg">
                    <span class="text-highlight-100 text-2xl font-bold">Dimers Pro</span>
                </div>
                <!-- Subheading -->
                <div class="text-highlight-100 font-semibold">
                    Every bet, every game, every day
                </div>
                <!-- Checklist -->
                <div class="checklist flex flex-col justify-center items-stretch">
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Unlimited best bets</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Unlimited props</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Unlimited trends</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Unlimited customizable parlays</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Bet filters</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Discord community</div>
                    </div>
                    <div class="checklist-row py-2 flex flex-row justify-center items-center gap-2">
                        <fa-icon [icon]="faCheckCircle" class="text-highlight-100 text-2xl leading-none"></fa-icon>
                        <div class="text-AIDimers-blackLight flex-1">Full site access</div>
                    </div>
                </div>
                <!-- Learn More link -->
                <div class="text-sm">
                    Want to know more? <a routerLink="/subscription" (click)="setVisible()">Go to Dimers Pro</a>
                </div>
                <!-- Testimonial -->
                <div class="testimonial-quote font-semibold text-AIDimers-black text-center">
                    "I thought I understood sports betting until I found Dimers. The daily betting edges are a game-changer."
                </div>
                <div class="testimonial-details flex flex-row justify-center items-center gap-1">
                    <img class="h-[34px] w-[40px]" height="34" width="40" alt="" src="https://imgix.cosmicjs.com/f52882d0-dd02-11ee-a698-6f33e3e4a628-Patrick-Sharyon-photo-3.png?auto=format,compress&h=68">
                    <div class="testimonial-text flex flex-col justify-center items-start">
                        <div class="source-name text-AIDimers-black text-sm font-semibold">Patrick Sharyon</div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="basicUser">
            <div class="flex md:hidden close-button leading-none top-2 right-2 cursor-pointer absolute h-[28px] min-w-[28px] bg-black/60 rounded-full items-center justify-center" (click)="setVisible()">
                <fa-icon class="text-white text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
            </div>
            <div class="block md:flex md:flex-row gap-6 justify-center rounded-t-xl md:rounded-3xl shadow overflow-auto bg-white">
                <div class="primary-panel bg-white text-black p-6 lg:p-8 flex-1 flex flex-col justify-center items-stretch gap-4 lg:gap-6 max-w-full aspect-[1240 / 211]">
                    <div class="shadow rounded-3xl border-0 p-3 md:p-6 bg-gradient-to-b from-AIDimers-grey to-white">
                        <div class="flex justify-center items-center">
                            <img src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-8 w-8" alt="logo">
                        </div>
                        <div class="text-highlight-100 font-semibold text-base md:text-lg text-center">
                            Don't miss out.
                        </div>
                        <div class="text-sm md:text-base font-semibold leading-6 text-center">
                            Dimers Pro users get access to an average of 200 bets per day
                        </div>
                    </div>
               
                    <!-- Frequency toggle -->
                    <div class="frequency-options flex flex-row justify-center items-stretch gap-2">
                        <div class="frequency-option flex-1 py-1 px-3 flex flex-col justify-center items-center shadow rounded-sm text-sm font-semibold leading-6"
                            [class]="selectedFrequency === option ? ' text-highlight-100' : 'cursor-pointer bg-white text-black'"
                            *ngFor="let option of frequencyOptions"
                            (click)="setFrequency(option)"
                        >
                            {{ option | titlecase }}
                        </div>
                    </div>
                    <!-- Price display -->
                    <div class="price-display flex flex-col justify-center items-stretch gap-1 text-AIDimers-black">
                        <div class="price-line flex flex-row justify-center items-baseline gap-1">
                            <span class="price-main text-3xl font-bold text-center">
                                <ng-container *ngIf="selectedFrequency === 'weekly'">$9.99</ng-container>
                                <ng-container *ngIf="selectedFrequency === 'monthly'"><span class="text-black"> $24.99</span></ng-container>
                                <ng-container *ngIf="selectedFrequency === 'yearly'">$249.99</ng-container>
                            </span>
                            <span class="price-note text-sm font-semibold">
                                <ng-container *ngIf="selectedFrequency === 'weekly'">/ week</ng-container>
                                <ng-container *ngIf="selectedFrequency === 'monthly'">/ month</ng-container>
                                <ng-container *ngIf="selectedFrequency === 'yearly'">/ year</ng-container>
                            </span>
                        </div>
                        <div class="price-note text-sm font-semibold text-center">
                            <ng-container *ngIf="selectedFrequency === 'weekly'"><span class="bg-AIDimers-grey text-gray-600 rounded block py-2">Flexibility at its best</span> </ng-container>
                            <ng-container *ngIf="selectedFrequency === 'monthly'"><span class="bg-AIDimers-grey text-gray-600 rounded block py-2">Save 40% compared to weekly</span></ng-container>
                            <ng-container *ngIf="selectedFrequency === 'yearly'">
                                <!-- <div class="flex items-center justify-center border-dashed border rounded-md py-2 border-black font-semibold text-gray-900 relative h-9">CODE: PICK6
                                    <button  (click)="copyCode()" cdkCopyToClipboard="PICK6" class="ml-3">
                                        <span id="default-icon flex flex-col" *ngIf="!startCopy">
                                            <fa-icon aria-hidden="true" [icon]="faCopy"></fa-icon>
                                        </span>
                                        <span id="success-icon" *ngIf="startCopy" class="inline-flex items-center text-green-600">
                                            <fa-icon aria-hidden="true" [icon]="faCheck"></fa-icon>
                                        </span>
                                    </button>
                                </div> -->
                                <span class="bg-AIDimers-grey text-gray-600 rounded block py-2">Save 50% when you sign up for annual</span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="sub-body block md:hidden">
                        <div class="block md:hidden mt-2">
                            <table class="table-auto md:table-fixed shadow rounded-2xl p-4 overflow-hidden bg-AIDimers-grey w-full">
                                <thead>
                                  <tr>
                                    <th class="text-sm font-bold">Features</th>
                                    <th class="text-sm font-bold">Basic</th>
                                    <th class="text-sm font-bold"> 
                                        <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="inline" loading="lazy" width="24" height="24"> 
                                        Pro
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Best Bets</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Best Props</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Parlays</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">1&nbsp;x&nbsp;day</td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Trends</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">
                                            Favorite games
                                        </td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
                                        </td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Bet filters</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/19358d70-d20a-11ee-babb-b99b48a02ac8-cross-red.svg">
                                        </td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-gray-800 text-sm font-medium">Discord</td>
                                        <td class="text-gray-800 text-sm font-medium bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/19358d70-d20a-11ee-babb-b99b48a02ac8-cross-red.svg">
                                        </td>
                                        <td class="text-highlight-100 text-sm font-semibold bg-white">
                                            <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                                src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                        </div>
                    </div>
                    <a class="subscribe-button" routerLink="/pro-purchase/{{ selectedFrequency }}">
                        Subscribe
                    </a> 
                    <div (click)="setVisible()" class="text-center text-sm text-gray-600 leading-6 font-medium cursor-pointer">
                        Continue as a basic user
                    </div>
                </div>
                <div class="secondary-panel bg-AIDimers-grey text-black p-8 md:p-6 lg:p-8 flex-1 hidden md:flex flex-col justify-center items-stretch">
                    <div class="hidden sm:block text-lg text-highlight-100 font-semibold mb-2">
                        What you’ll get
                    </div>
                    <table class="table-fixed shadow rounded-2xl p-4 overflow-hidden">
                        <thead>
                          <tr>
                            <th class="text-sm font-bold">Features</th>
                            <th class="text-sm font-bold">Basic</th>
                            <th class="text-sm font-bold"> 
                                <img alt="pro-logo" class="inline" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" loading="lazy" width="24" height="24"> 
                                Pro
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">Best Bets</td>
                            <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                          </tr>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">Best Props</td>
                            <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                          </tr>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">Parlays</td>
                            <td class="text-gray-800 text-sm font-medium bg-white">1&nbsp;x&nbsp;day</td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                          </tr>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">Trends</td>
                            <td class="text-gray-800 text-sm font-medium bg-white">3&nbsp;x&nbsp;day</td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">Unlimited</td>
                          </tr>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">
                                Favorite games
                            </td>
                            <td class="text-gray-800 text-sm font-medium bg-white">
                                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
                            </td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">
                                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto"
                                    src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg" />
                            </td>
                        </tr>
                          <tr>
                            <td class="text-gray-800 text-sm font-medium">Bet filters</td>
                            <td class="text-gray-800 text-sm font-medium bg-white">
                                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto" src="https://imgix.cosmicjs.com/19358d70-d20a-11ee-babb-b99b48a02ac8-cross-red.svg">
                            </td>
                            <td class="text-highlight-100 text-sm font-semibold bg-white">
                                <img alt="icon" width="20" height="20" loading="lazy" class="block mx-auto" src="https://imgix.cosmicjs.com/52fb5e80-d148-11ee-babb-b99b48a02ac8-checkcircleFILL0wght400GRAD0opsz24-1.svg">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </ng-container>
        <div class="hidden close-button cursor-pointer md:flex items-center justify-center h-[32px] min-w-[32px] bg-black/60 rounded-full ml-2 hover:bg-black" (click)="setVisible()">
            <fa-icon class="text-white" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
    </div>
</div>