<div #blockBody class="external-value-bets">
	<!-- <div class="value-bets-header" *ngIf="!configurationObject.hideHeader">
		<span class="value-bets-title">
			{{ localisationService.localeTerm('value_picks', 'all-caps') }}
		</span>
	</div> -->
    <div class="flex flex-col sm:flex-row sm:items-center sm:gap-2.5" >
        <div class="mobile-filters sort-btn"  [class.active]="sortToggle"  *ngIf="!valuePlaysLoading && !valuePlaysError">
            <div class="flex gap-2 ">
                <button (click)="openFilter()" class="flex max-sm:w-1/2 justify-between h-9 bg-white px-2 sm:px-4 py-2 text-gray-900 gap-2.5 sm:gap-3 items-center shadow shadow-slate-300 hover:bg-slate-200 rounded text-sm font-semibold ">
                    <img alt="Dimers Pro Feature" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5">
                    <span class="hidden sm:inline-block">
                        Filter Best Bets
                    </span>
                    <span class="inline-block sm:hidden">
                        All Filters
                    </span>
                    <fa-icon [icon]="faSliders"></fa-icon>
                </button>
                <button class="flex-auto flex max-sm:w-1/2 justify-between h-9 bg-white px-2 sm:px-4 py-2 text-gray-900 gap-2.5 sm:gap-3 items-center shadow shadow-slate-300 hover:bg-slate-200 rounded text-sm font-semibold" (click)="toggleSortOptions()">
                    <img alt="Dimers Pro Feature" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5">
                    <span class="overflow-hidden text-ellipsis whitespace-nowrap">
                        {{ sortCategoryLabel }}
                    </span>
                    <fa-icon [icon]="faArrowsUpDown"></fa-icon>
                </button>
            </div>
            <div>
                <ul class="options max-h-[350px] ml-0 bg-white shadow shadow-slate-300 ">
                    <li *ngFor="let item of sortCategories" class="option text-sm font-medium text-gray-900 flex justify-between" (click)="setBetSort(item)">
                        {{ item.label }}
                        <fa-icon *ngIf="betSort === item.sort" [icon]="faCheck" class='checkbox-checked text-highlight-100 text-base'></fa-icon>
                    </li>
                </ul>
            </div>
           
        </div>
    </div>

	<div class="value-bets-notes mt-4">
		<span class="last-updated">
			<span *ngIf="!valuePlaysLoading && !valuePlaysError && displayedValuePlays.length > 0">
				Last Updated: <span class="lu-time">{{ lastUpdatedString() }}</span>
			</span>
		</span>
		
		<span class="compact-only" >
			<fa-icon class="shortlist-icon" [icon]="faStarSolid"></fa-icon>
			Add {{ localisationService.localeTerm('match', '') }}
				to {{ localisationService.localeTerm('shortlist', 'title-case') }}
		</span>

		<span class="flex flex-row gap-1 justify-start items-center value-key">
			<img loading="lazy"
			  class="bookie-icon"
			  src="https://content-blocks-node.azurewebsites.net/static/assets/value-fire.png"
			  alt=""
			  class="h-4 w-auto inline"
			>
			Value Identified
		</span>
	</div>

	<div class="value-bets-list">
		<div class="bets-placeholder" *ngIf="valuePlaysLoading && !valuePlaysError">
			<span>
				Loading {{ localisationService.localeTerm('value_picks', 'title-case') }}<span class="animated-ellipsis"><span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span></span>
			</span>
		</div>
		<div class="bets-placeholder" *ngIf="valuePlaysError">
			<span class="error-main">
				<span>
					We are having trouble loading {{ localisationService.localeTerm('value_picks', 'title-case') }} data
				</span>
			</span>

			<span>
				Please
				<span class="retry-link"
					(click)="retrieveValueBets()">
					try again
				</span>
			</span>
		</div>
		<div class="bets-placeholder" *ngIf="!valuePlaysLoading && !valuePlaysError && valuePlays.length === 0">
			No {{ localisationService.localeTerm('value_picks', 'title-case') }} available, please try again later.
		</div>
		<div class="bets-placeholder"
			*ngIf="!valuePlaysLoading && !valuePlaysError && valuePlays.length > 0 && displayedValuePlays.length === 0"
		>
			No {{ localisationService.localeTerm('value_picks', 'title-case') }} available for your chosen filters
		</div>
		<ng-container *ngFor="let play of displayedValuePlays; index as i">
			<app-value-bet-row
				ngSkipHydration
				class="value-bet" 
				[play]="play"
				[proOnly]="i >= freeLimit"
			>
			</app-value-bet-row>
			<div class="pro-banner w-full rounded-sm flex flex-col sm:flex-row justify-center sm:justify-between items-center gap-2.5 cursor-pointer" *ngIf="(proUser$ | async) === false && i === freeLimit - 1 && displayedValuePlays.length > freeLimit" (click)="openProModal()">
				<img class="hidden md:flex h-20 w-20" src="https://imgix.cosmicjs.com/b267e050-dab2-11ee-a698-6f33e3e4a628-Best-Bets-icon.svg?auto=format" alt="" height="80" width="80">
				<span class="text-2xl font-bold sm:flex-1">{{ proBannerText }}</span>
				<div class="pro-button">
					<img alt="" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2">
					Get Dimers Pro
				</div>
			</div>
		</ng-container>
		  
		<div class="show-more-ctr" *ngIf="showMoreVisible">
			<div class="show-more-btn" (click)="showMorePicks()">
				Show More Bets
			</div>
		</div>
	</div>
	
    <div class="bookie-logo-row bg-AIDimers-grey rounded-xl flex flex-col gap-6 justify-center p-4 md:p-8 mt-4">
        <div class="text-base md:text-2xl font-bold text-gray-900 leading-7 text-center">
            We Compare Odds From the Best Sportsbooks
        </div>
        <div class="flex justify-between md:justify-around items-center gap-2 md:gap-6 flex-wrap">
            <div *ngFor="let option of bookOptions" class="flex flex-col gap-2 items-center justify-start">
                <img loading="lazy" class="rounded-md overflow-hidden h-10 w-10 md:h-20 md:w-20" [src]="option | bookIcon" alt="" width="80" height="80">
                <div class="text-sm md:text-xl text-gray-900 leading-7 font-semibold md:font-bold">{{ betService.stylisedBookName(option) }}</div>
            </div>
        </div>
		<a routerLink="/multiple-sportsbooks-guide"
			class="bg-white max-sm:flex-1 flex justify-center h-9 px-2 sm:px-4 py-2 text-gray-900 gap-2.5 sm:gap-3 items-center shadow shadow-slate-300 hover:bg-slate-200 hover:text-gray-900 rounded text-sm font-semibold"
		>
			Why Join Multiple Sportsbooks?
		</a>
    </div>
    
	<!-- <div class="bookie-key single-row">
		<div class="key-ctr">
			<div class="key-listing">
				<img loading="lazy"
					class="bookie-icon"
					src="https://content-blocks-node.azurewebsites.net/static/assets/value-fire.png"
					alt=""
				>
				<div class="bookie-name">Value Identified</div>
			</div>
		</div>
	</div> -->
</div>
<app-best-bet-mobile-filter
[(filterSports)]="filterSports"
[valueBetsSports]="valueBetsSports"
[(filterBetTypes)]="filterBetTypes"
[setFilterTimeframe]="setFilterTimeframe"
[displayedValuePlays]="currentAvailableBets"
[filterTimeframe]="filterTimeframe"
[toggleFilterBetType]="toggleFilterBetType"
[toggleAllFilterBetType]="toggleAllFilterBetType"
[toggleFilterSport]="toggleFilterSport"
[toggleAllFilterSports]="toggleAllFilterSports"
[(mobileFilterOpen)]="mobileFilterOpen"
>
</app-best-bet-mobile-filter>
