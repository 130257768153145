<div class="-mt-5">
    <ng-container *ngIf="stateCode === 'vermont' && status ==='Pre launch' ">
        <section class="tag-section flex-row flex-wrap py-2 items-center gap-2" >
            <button class="flex border border-highlight-100 bg-highlight-100 text-white h-8 items-center justify-center text-sm px-2 rounded font-medium">
                <img class="mr-1" src="https://cdn.cosmicjs.com/c9813100-115a-11ee-a61c-b352d78f91a5-Vermont.svg" alt="" width="20"
                    height="20">
                {{ title }}
            </button>
            <button class="border border-highlight-100 text-sm rounded h-8 px-1 sm:px-4 text-highlight-100 font-semibold uppercase">
                <span class="dot h-2 w-2 bg-yellow-400 rounded-[50%] inline-block"></span>
                legal soon
            </button>
        </section>
    </ng-container>
    <section class="hero-section text-white">
        <div class="w-full max-w-[1270px] mx-auto px-[10px] flex flex-col gap-4 py-4 sm:py-8">
            <h1 *ngIf="title" class="text-2xl md:text-4xl lg:text-5xl font-bold text-neutral-100">
                <!-- {{ title }} Sports Betting: Best {{ titleAbv }} Sportsbooks -->
                {{ title }}
            </h1>
            <div *ngIf="subTitle" class="hidden sm:block font-semibold text-xl text-neutral-100">
                <!-- <span *ngIf="title === 'North Carolina'">
                    Online sports betting will be legal in North Carolina from March 11, 2024. Watch this space for pre-registration offers coming soon. Can't wait? These partners are already live in North Carolina:
                </span>
                <span *ngIf="title !== 'North Carolina'">
                    Compare the best sportsbooks in {{ title }} with our detailed analysis.
                </span> -->
                {{ subTitle }}
            </div>
            <div class="flex items-center gap-2 font-base font-medium text-white pb-2 md:hidden">
                <img class="m-0 w-auto" src="https://imgix.cosmicjs.com/d6a67810-474a-11ee-89ab-17371fc03105-swipe-logo.svg?auto=format,compress&w=20" alt="logo">
                Swipe across to view more offers
            </div>
            <div class="sportsbook-table text-black">
                <swiper-container slides-per-view="auto" class="mySwiper">
                    <swiper-slide *ngFor="let offer of offers | keyvalue:sortingOffers; let i = index;">
                        <ng-container *ngIf="i <= 3">
                            <div class="w-[295px] flex flex-col rounded-lg relative shadow overflow-hidden" [class.border-amber-400]="i === 0">
                                <!-- <div class="absolute right-6 top-4" *ngIf="i===0">
                                    <img src="https://imgix.cosmicjs.com/5954d8c0-0433-11ee-9f7c-f92e9d2a9f62-BestBook-Badge-1.png" alt="sportsbook badge" width="40" height="40">
                                </div> -->
                                <div class="px-4 flex items-center justify-between h-16"
                                [style.background-color]="offer?.value[0].metadata.sportsbook[0].metadata.hex_color">
                                    <img [src]="offer.value[0].metadata.sportsbook[0].metadata.dark_bg_logo.imgix_url + '?auto=format,compress&h=40'"  alt="logo" class="h-10 w-[108px]"
                                    loading="lazy">
                                    <div class="flex flex-row gap-2 items-center">
                                        <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull"></fa-icon>
                                        <div class="text-white font-semibold">
                                        {{ offer.value[0].metadata.sportsbook[0].metadata.rating.overall_score}}
                                        </div> 
                                    </div>
                                </div>
                                <div class="p-4 bg-white flex flex-col gap-3">
                                        <div class="text-sm uppercase text-highlight-100 font-bold">
                                            Best for  {{ offer.value[0].metadata.sportsbook[0].metadata.best_for}}
                                        </div>
                                        <!-- <div class="flex flex-row gap-2 items-center">
                                            <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull"></fa-icon>
                                            <div class="text-neutral-600 font-semibold">
                                                {{ offer.value[0].metadata.sportsbook[0].metadata.rating.overall_score}}
                                            </div> 
                                        </div> -->
                                    <!-- <div class="flex flex-row gap-2 items-baseline">
                                        <span class="font-bold text-4xl text-highlight-100">
                                            {{offer.value[0].metadata.promos_value}}
                                        </span>
                                        <span class="font-bold text-sm text-gray-900">
                                            / USD Max
                                        </span>
                                    </div>
                                    <div class="min-h-[40px] flex items-center">
                                        <a class="underline text-highlight-100 font-semibold text-sm hover:text-highlight-100 line-clamp-2" 
                                        attr.data-recording-click-props='{
                                            "offer-bookmaker": "{{ offer.value[0].metadata.sportsbook[0].slug }}",
                                            "offer-component": "best-books",
                                            "offer-location": "header-sign-up-link",
                                            "offer-state-code": "{{ stateCode }}"
                                        }'
                                        (click)="redirectBookiePromoLink(
                                            offer.value[0].metadata.promos_link,
                                            {src: 'bookies'},
                                            offer.value[0].metadata.sportsbook[0]
                                        )">{{offer.value[0].metadata.promos_title}}</a>
                                    </div> -->
                                    <div class="bg-gray-100 p-3 rounded flex flex-col gap-3 cursor-pointer" 
                                            (click)="redirectBookiePromoLink(
                                                offer.value[0].metadata.promos_link,
                                                {src: 'best-book', promo: 'true'},
                                                offer.value[0].metadata.sportsbook[0]
                                            )">
                                        <div class="flex flex-col gap-2 justify-evenly offer-content">
                                            <div class="flex items-center justify-center gap-2 flex-wrap" *ngIf="offer.value[0]?.metadata?.tags">
                                                <ng-container *ngFor="let tag of offer.value[0]?.metadata?.tags">
                                                    <div class="rounded-xl px-2 uppercase text-[12px] font-medium"
                                                        [style.background-color]="tag.metadata.bg_hex_color" [style.color]="tag.metadata.text_hex_color">
                                                        {{ tag.metadata.text}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="text-highlight-100 text-base font-bold text-center header leading-5">
                                                {{ offer.value[0].metadata.promos_title }}
                                            </div>
                                            <!-- <div class="text-sm font-medium text-center leading-4 sub-header">
                                                                                {{ offer.value[0].metadata.promos_title }}
                                                                            </div> -->
                                        </div>
                                        <button class="claim-button" 
                                        attr.data-recording-click-props='{"offer-bookmaker": "{{offer.value[0].metadata.sportsbook[0].slug}}","offer-location":best-book,"offer-state-code":"{{ stateCode }}"}'
                                        >
                                            {{ offer.value[0].metadata.promos_button_text }}
                                        </button>
                                    </div>
                                    <div class="divide-y divide-gray-200 border-t border-b border-gray-200 font-medium font-base text-neutral-500 w-full">
                                        <div class="py-2 flex flex-row justify-between">
                                            <div class="text-sm">
                                                Betting features
                                            </div>
                                            <div class="flex flex-row gap-2 items-center min-w-[50px]">
                                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                                </fa-icon>
                                                <div class="font-semibold text-sm text-neutral-600">
                                                    {{ offer.value[0].metadata.sportsbook[0].metadata.rating.betting_features }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="py-2 flex flex-row justify-between">
                                            <div class="text-sm">
                                                Money aspects
                                            </div>
                                            <div class="flex flex-row gap-2 items-center min-w-[50px]">
                                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                                </fa-icon>
                                                <div class="font-semibold text-sm text-neutral-600">
                                                    {{ offer.value[0].metadata.sportsbook[0].metadata.rating.money_rewards }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="py-2 flex flex-row justify-between">
                                            <div class="text-sm">
                                                User experience
                                            </div>
                                            <div class="flex flex-row gap-2 items-center min-w-[50px]">
                                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                                </fa-icon>
                                                <div class="font-semibold text-sm text-neutral-600">
                                                    {{ offer.value[0].metadata.sportsbook[0].metadata.rating.user_experience }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="py-2 flex flex-row justify-between" *ngIf="stateCode !=='ontario'">
                                            <div class="text-sm">
                                                Bonuses and promos
                                            </div>
                                            <div class="flex flex-row gap-2 items-center min-w-[50px]">
                                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                                </fa-icon>
                                                <div class="font-semibold text-sm text-neutral-600">
                                                    {{ offer.value[0].metadata.sportsbook[0].metadata.rating.bonuses_and_promos }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="py-2 flex flex-row justify-between">
                                            <div class="text-sm">
                                                Customer support
                                            </div>
                                            <div class="flex flex-row gap-2 items-center min-w-[50px]">
                                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                                </fa-icon>
                                                <div class="font-semibold text-sm text-neutral-600">
                                                    {{ offer.value[0].metadata.sportsbook[0].metadata.rating.customer_support }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex items-center cursor-pointer" (click)="toggleOfferDropdown($event, i, offer.value[0].metadata?.sportsbook[0]?.slug)">
                                            <ng-container *ngIf="!offer.value[0].metadata?.sportsbook[0]?.metadata?.review && !offer.value[0].metadata?.sportsbook[0]?.metadata?.dfs_review && !offer.value[0].metadata?.sportsbook[0]?.metadata?.canadian_reviews  ; else reviewButton" >
                                                <div class="text-sm font-medium text-highlight-100 ml-auto">
                                                    T&C's apply.
                                                </div>
                                                <div class="cursor-pointer ml-auto">
                                                    <fa-icon class="text-xs text-black" *ngIf="!offerDropdownOpen[i]" [icon]="faChevronDown"></fa-icon>
                                                    <fa-icon class="text-xs text-black" *ngIf="offerDropdownOpen[i]" [icon]="faChevronUp"></fa-icon>
                                                </div>                                        
                                            </ng-container>
                                            <ng-template #reviewButton>
                                                <div class="text-sm font-medium text-highlight-100" *ngIf="offer.value[0].metadata?.sportsbook[0]?.metadata?.review">
                                                    <a class="text-highlight-100" href="/best-books/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}" routerLink="/best-books/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}">
                                                        Read review
                                                    </a>
                                                </div>
                                                <div class="text-sm font-medium text-highlight-100" *ngIf="offer.value[0].metadata?.sportsbook[0]?.metadata?.dfs_review">
                                                    <a class="text-highlight-100" href="/dfs/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}" routerLink="/dfs/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}">
                                                        Read review
                                                    </a>
                                                </div>
                                                <div class="text-sm font-medium text-highlight-100" *ngIf="offer.value[0].metadata?.sportsbook[0]?.metadata?.canadian_reviews">
                                                    <a class="text-highlight-100" href="/ca/betting/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}" routerLink="/ca/betting/reviews/{{ offer.value[0].metadata?.sportsbook[0]?.slug }}">
                                                        Read review
                                                    </a>
                                                </div>
                                                <div class="flex gap-2 items-center ml-auto">
                                                    <div class="text-sm font-medium text-highlight-100">
                                                        T&C's apply.
                                                    </div>
                                                    <div class="cursor-pointer">
                                                        <fa-icon class="text-xs text-highlight-100" *ngIf="!offerDropdownOpen[i]" [icon]="faChevronDown"></fa-icon>
                                                        <fa-icon class="text-xs text-highlight-100" *ngIf="offerDropdownOpen[i]" [icon]="faChevronUp"></fa-icon>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="mt-4" *ngIf="offerDropdownOpen[i]">
                                            <div class="text-darkPrimary-50 text-xs"
                                                [innerHTML]="offer.value[0].metadata.promos_disclaimer | trustCosmic"></div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </ng-container>
                     
                    </swiper-slide>
                </swiper-container>
            </div>
            <a href="/best-books/methodology" routerLink="/best-books/methodology" class="flex flex-row gap-2 items-center text-base sm:text-xl font-semibold text-white hover:text-highlight-100">
                How do Dimers' ratings work?
                <fa-icon class="sm:flex items-center hidden " [icon]="faArrowUpRight"></fa-icon>
            </a>
        </div>
    </section>
</div>